<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="考试基本信息"
      @head-save="headSave"
      @head-save-back="headSave(true)"
      @head-submit="headSubmit"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm.sync="dataForm"
    ></form-layout>
    <head-layout
      head-title="参考人员"
      :head-btn-options="headBtn"
      @head-add-tabs="headAddTabs"
      @head-remove-tabs="headRemoveTabs"
    />
    <grid-head-layout ref="searchForm" :search-columns="searchColumns" v-model="searchForm"
                      @grid-head-search="searchChange" @grid-head-empty="searchReset"/>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoadExamUser"
      @gird-handle-select-click="selectionChange"
    ></grid-layout>
    <CommonDialog v-if="roleBox" width="90%" :dialogTitle="$t('cip.plat.sys.user.field.roleConfig')" @cancel="roleBox = false" @confirm="submitBox">
      <departmentPersonnel ref="departmentPersonnel" v-if="roleBox"
                           :disabled-list="disabledErpItemList"></departmentPersonnel>
    </CommonDialog>
    <el-dialog
      title="试卷列表"
      :visible.sync="dialogVisible"
      append-to-body
      :close-on-click-modal="false"
      class="avue-dialog avue-dialog--top examPaper"
      width="80%"
    >
      <exampaper-dialog
        v-if="dialogVisible"
        @getSelectionData="getExamPaperData"
      ></exampaper-dialog>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import exampaperDialog from "./exampaperDialog";

import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {add, getDetail, remove, update, save} from "@/api/training/eduexam";
import {getLineList, eduexamuser, removeLine} from "@/api/training/eduexamuser";
import {getList, roleAllocation} from "@/api/system/user";
import {userDeptList} from "@/api/system/dept";
import departmentPersonnel from "./departmentPersonnel.vue";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import CommonDialog from "@/components/CommonDialog"

export default {
  name: "eduexamEdit",
  mixins: [exForm, draft],
  components: {
    departmentPersonnel,
    FormLayout,
    HeadLayout,
    GridLayout,
    exampaperDialog,
    CommonDialog
  },
  data() {
    return {
      processTemplateKey: "iframe_train_examination",
      // form: {
      //   status: 1,
      // },
      formProcess: {
        id: '',
        processId: '',
        paperName: '',
      },
      type: '',
      roleBox: false,
      tableLoading: false,
      dialogVisible: false,
      query: {},
      dataForm: {},
      saveLoading: false,
      handleSelectedValue: [], //操作后选中的值
      examuserData: [],
      selectData: [],
      prop: undefined,
      // type: undefined,
      selectionList: [],
      disabledErpItemList: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      searchColumns: [
        {
          label: '',
          prop: "account",
          span: 4,
          placeholder: "请输入登陆账号",
        },
        {
          label: '',
          prop: "deptName",
          span: 4,
          placeholder: "请输入所属公司",
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    ids() {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        if (ele.id) {
          ids.push(ele.id);
        }
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        menu: false,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "登录账号",
            prop: "account",
            width: 400,
            align: 'left',
            overHidden: true,
          },
          {
            label: "用户姓名",
            prop: "realName",
            width: 400,
            align: 'left',
            overHidden: true,
          },
          {
            label: "所属公司",
            prop: "deptName",
            align: 'left',
            overHidden: true,
          }
        ]
      }
    },
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduexam_add, true),
        viewBtn: this.vaildData(this.permission.eduexam_view, true),
        delBtn: this.vaildData(this.permission.eduexam_delete, true),
        editBtn: this.vaildData(this.permission.eduexam_edit, true)
      };
    },
    headBtn() {
      if (this.dataForm.status === 2 || this.dataForm.status === 4) {
        let buttonBtn = [];
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            btnOptType: 'primary',
          });
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove-tabs",
            type: "button",
            btnOptType: 'remove',
          });
        return buttonBtn;
      }
    },
    headBtnOptions() {
      if (this.type !== 'workflow') {
        return [
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
            loading: this.saveLoading,
          },
          {
            label: this.$t('cip.cmn.btn.addBtn'),
            emit: "head-save-back",
            type: "button",
            icon: "",
            btnOptType: 'saveBack',
            loading: this.saveLoading,
          },
          {
            label: this.$t('cip.cmn.btn.submitBtn'),
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: 'submitBack',
            loading: this.saveLoading,
          },
          {
            label: this.$t('cip.cmn.btn.cancel'),
            emit: "head-cancel",
            type: "button",
            icon: "",
            btnOptType: 'cancel',
            loading: this.saveLoading,
          }
        ]
      } else {
        if (this.dataForm.status === 4) {
          return [
            {
              label: this.$t('cip.cmn.btn.addBtn'),
              emit: "head-save",
              type: "button",
              icon: "",
              btnOptType: 'save',
              loading: this.saveLoading,
            },
          ]
        }
      }
    },
    formColumn() {
      return [
        {
          label: "考试编号",
          prop: "code",
          readonly: true,
          width: 180,
          placeholder: "保存后自动生成",
          rules: [{
            required: false,
            message: "请输入考试编号",
            trigger: "blur"
          }]
        },
        {
          label: "考试试卷",
          prop: "paperName",
          placeholder: "请选择考试试卷",
          click: () => {
            if (this.type === 'add' || this.type === 'edit' || this.dataForm.status === 4) {
              this.handleExamPaper();
            }
          },
          readonly: true,
          rules: [{
            required: true,
            message: "请选择考试试卷",
            trigger: "change"
          }]
        },
        // {
        //   label: "考试时间",
        //   prop: "examTime",
        //   type: "datetime",
        //   format: "yyyy-MM-dd HH:mm:ss",
        //   valueFormat: "yyyy-MM-dd HH:mm:ss",
        //   rules: [{
        //     required: true,
        //     message: "请输入考试时间",
        //     trigger: "blur"
        //   }]
        // },
        {
          label: "考试地点",
          prop: "examAddress",
          maxlength: 100,
          showWordLimit: true,
          rules: [{
            required: true,
            message: "请输入考试地点",
            trigger: "blur"
          }]
        },
        {
          label: "备注",
          prop: "note",
          type: "textarea",
          overHidden: true,
          span: 24,
          minRows: "2",
          maxRows: "4",
          maxlength: 100,
          showWordLimit: true,
          rules: [{
            required: false,
            message: "请输入试卷说明",
            trigger: "blur"
          }]
        },
      ];
    },
  },
  methods: {
    //提交
    headSubmit() {
      if ((!this.dataForm.id) || (this.tableData.length === 0)) {
        this.$message.warning("请确认考试基本信息和参考人员数是否正确！");
      } else {
        this.$refs.formLayout.$refs.form.validate(valid => {
          if (valid) {
            this.$confirm("确定将提交审核?", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.$loading();
                let dataForm = this.$refs.formLayout.dataForm
                this.rpTaskAttributeSubmit(dataForm);
              })
              .then(() => {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
                this.$loading().close();
              });
            this.$refs.formLayout.$refs.form.allDisabled = false
          }
        })
      }
    },
    rpTaskAttributeSubmit(data) {
      this.formProcess.id = data.id;
      this.formProcess.paperName = this.dataForm.paperName;
      this.handleStartProcess5(true).then((res) => {
        data.processInstanceId = res.data.data;
        data.status = 3; // 设置状态为新增生成 审核中
        save(data).then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
          }
        })
      });
    },
    // 获取流程Id
    getProcessId() {
      getProcessList(1, 100).then((res) => {
        let processList = res.data.data.records
        if (processList.length === 0) {
          this.$message({
            type: "warning",
            message: "流程列表为空，请联系管理员",
          });
          return;
        }
        let process = processList.filter(
          (item) => item.key === this.processTemplateKey
        )[0];
        if (process === undefined) {
          this.$message({
            type: "warning",
            message: "未查询到该流程，请联系管理员",
          });
          return;
        }
        this.submitLoading = false;
        this.tag.label = process.name;
        this.formProcess.processId = process.id;
      });
    },
    submitBox() {

      // eduexamuser(form).then(res => {
      //   this.onLoad(this.page);
      //   this.$message({
      //     type: "success",
      //     message: "操作成功!"
      //   });
      //   this.roleBox = false
      // })
      var confirmTheSubmission = this.$refs.departmentPersonnel.confirmTheSubmission();
      console.log("进行输出---------", confirmTheSubmission)
      confirmTheSubmission.forEach(e => {
        let cc = {
          "account": e.account,
          "realName": e.realName,
          "deptId": e.deptId,
          "deptName": e.deptName,
          "userId": e.userId,
          "examId": this.dataForm.id
        }
        this.tableData.push(cc)
      })
      this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
      eduexamuser(this.tableData)
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
          this.onLoadExamUser(this.page);
        })
      this.roleBox = false
    },
    headAddTabs() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存考试基本信息数据！");
      } else {
        this.roleBox = true
      }
    },
    headRemoveTabs() {

      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t(`cip.cmn.btn.defBtn`),
        cancelButtonText: this.$t(`cip.cmn.btn.celBtn`),
        type: "warning"
      })
        .then(() => {
          if (this.ids.length > 0) {
            this.tableLoading = true;
            removeLine(this.ids)
              .then(() => {
                let query = {}
                this.$message({
                  type: "success",
                  message: this.$t('cip.cmn.msg.success.operateSuccess')
                });
                this.$refs.gridLayOut.selectionClear()
                this.onLoadExamUser(this.page);
              });
            this.tableLoading = false
          } else {
            let indexes = this.selectionList.filter(e => e.id == null)
            for (let i = this.tableData.length - 1; i >= 0; i--) {
              let item = this.tableData[i]
              if (indexes.filter(e => e.deptId === item.deptId && e.userId === item.userId).length > 0) {
                this.tableData.splice(i, 1)
                this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
              }
            }
            this.$refs.gridLayOut.selectionClear()
          }
        })
    },
    // rowRemove(row) {
    //   this.$confirm("确定将选择数据删除?", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   })
    //     .then(() => {
    //       return remove(row.id);
    //     })
    //     .then(() => {
    //       this.onLoad(this.page);
    //       this.$message({
    //         type: "success",
    //         message: "操作成功!"
    //       });
    //     });
    // },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoadExamUser(this.page, query)
    },
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      this.onLoadExamUser(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoadExamUser(this.page, this.searchColumns)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.page.currentPage = 1
      this.onLoadExamUser(this.page, this.searchColumns)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoadExamUser(this.page, params)
    },
    searchReset() {
      this.query = {};
      this.onLoadExamUser(this.page)
    },
    handleExamPaper() {
      if (this.type == "view") {
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    headSave(clo = false) {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          this.$loading();
          let examUserList = [];
          // for (let i = 0; i < this.handleSelectedValue.length; i++) {
          //   let user = {userId: this.handleSelectedValue[i]};
          //   examUserList.push(user);
          // }
          this.tableData.forEach(e => {
            let cc = {
              "account": e.account,
              "realName": e.realName,
              "deptId": e.deptId,
              "deptName": e.deptName,
              "userId": e.userId,
            }
            examUserList.push(cc)
          })
          let dataForm = this.$refs.formLayout.dataForm
          dataForm.eduExamUserList = examUserList;
          this.saveLoading = true
          update(dataForm).then(
            (res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.$refs.formLayout.dataForm = res.data.data;
              if (clo) {
                this.headCancel();
              }
            },
            (error) => {
              window.console.log(error);
            }
          ).finally(() => {
            this.saveLoading = false;
            this.$loading().close();
            this.$refs.formLayout.$refs.form.allDisabled = false
          });
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    handleChange(value, direction, movedKeys) {
      this.handleSelectedValue = value;
    },
    // onLoad(page, params = {}) {
    //   this.loading = true;
    //   userDeptList(
    //     page.currentPage,
    //     page.pageSize,
    //     Object.assign(params, this.query)
    //   ).then((res) => {
    //     const data = res.data.data;
    //     this.page.total = data.total;
    //     this.tableData = data.records;
    //     this.loading = false;
    //   });
    // },
    selectList() {
      this.disabledErpItemList = []
      getLineList(
        this.page.currentPage, 500, this.query
      ).then(res => {
        const data = res.data.data;
        this.disabledErpItemList = data.records.map(e => e.userId + "" + e.deptId);
      });
    },
    onLoadExamUser(page, params = {}) {
      this.loading = true;
      this.handleSelectedValue = [];
      if (!params.examId) {
        params.examId = this.dataForm.id
      }
      getLineList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.loading = false;
        this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
      });

      getList(
        {
          ...params, ...this.query,
          current: page.currentPage,
          size: page.pageSize,
        }).then((res) => {
        const data = res.data.data;
        this.examuserData = data.records;
        this.loading = false;
      });
    },
    formData(id) {
      getDetail(id).then((res) => {
        this.dataForm = res.data.data;
        if (![2, 4].includes(this.dataForm.status)) {
          this.$refs.formLayout.$refs.form.allDisabled = true
        }
      });
    },
    getExamPaperData(row) {
      this.dataForm.paperId = row.id;
      this.dataForm.paperName = row.paperName;
      this.dialogVisible = false;
    },
  },
  mounted() {

    let type = this.$route.query.type;
    this.type = type
    if (type === 'workflow') {
      this.dataForm.id = this.$route.query.id
      getDetail(this.dataForm.id).then((res) => {
        this.dataForm = res.data.data;
        if (![2, 4].includes(this.dataForm.status)) {
          this.$refs.formLayout.$refs.form.allDisabled = true
        }
      })
      this.formData(this.dataForm.id);
      this.query.examId = this.dataForm.id;
      this.onLoadExamUser(this.page);
    } else {
      if (this.$route.query.row) {
        let row = JSON.parse(this.$route.query.row);
        this.dataForm.id = row.id;
      }
      this.prop = this.$route.query.prop;
      this.type = this.$route.query.type;
      if (this.type == "add") {
        this.dataForm.id = "";
        this.onLoadExamUser(this.page);
      } else if (this.type == "edit") {
        let row = JSON.parse(this.$route.query.row);
        this.formData(this.dataForm.id);
        this.dataForm.paperName = row.paperName;
        this.query.examId = this.dataForm.id;
        this.onLoadExamUser(this.page);
      } else if (this.type == "view") {
        this.formData(this.dataForm.id);
        let row = JSON.parse(this.$route.query.row);
        this.dataForm.paperName = row.paperName;
        this.query.examId = this.dataForm.id;
        this.onLoadExamUser(this.page);
      }
    }
    this.getProcessId()
    this.selectList();
  },
  created() {
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-transfer-panel {
  width: 30vw;
  height: 100%;
}

::v-deep .el-transfer-panel__body {
  height: calc(100% - 55px);
}

::v-deep .el-transfer-panel__list {
  height: calc(100% - 47px) !important;
}

::v-deep .el-checkbox {
  margin-left: 0px !important;
}

.examPaper ::v-deep .el-table {
  height: 392px !important;
}

.examPaper ::v-deep .el-table__body-wrapper {
  max-height: 358px !important;
  overflow-y: auto;
}

.examPaper ::v-deep .el-dialog__body {
  margin-bottom: 0 !important;
}
</style>
