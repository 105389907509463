<template>
  <el-container class="main-box flex-container">
    <CommonTreeNew
      treeTitle="作业票类型"
      node-key="id"
      isLeafIcons
      isShowdig
      :defaultExpandedKeys="[]"
      ref="commonTreeCore"
      @getTreeAdd="getTreeAdd"
      @getTreeDelete="rowDel([$event], 0)"
      :defaultProps="defaultProps"
      @getNodeClick="gridRowClick"
      :showCheckbox="false"
      :isShowdig="false"
      @getTreeData="commonTreeData"
      url="/api/sinoma-system/dict-biz/dictionary?code=work_permit_type"
      :urlParmas="{}"
    ></CommonTreeNew>
    <div class="table-box">
      <head-layout
        :head-btn-options="corePersonnelHeadBtnOptions"
        @head-export="headExport"
        @head-del="headDel"
        @handleImport="handleImport"
        @head-add="addjcx"
        head-title="作业票安全措施"
      ></head-layout>
      <grid-layout
        class="gridLayOut"
        ref="gridLayOut"
        :table-options="corePersonnelTableOption"
        :data-total="page.total"
        :page="page"
        @page-current-change="onload1"
        @page-size-change="onload1"
        :table-data="tableData1"
        :table-loading="TableLoading1"
        @gird-handle-select-click="selectionChange"
      >
        <template #customBtn="{ row }">
          <el-button type="text" size="small" @click="addjcx(row)"
          >编辑
          </el-button
          >
          <el-button type="text" size="small" @click="rowDel([row], 1)"
          >删除
          </el-button
          >
        </template>
      </grid-layout>
    </div>
    <el-dialog
      title="作业活动清单"
      v-if="jcxflModel"
      v-dialogDrag
      :visible.sync="jcxflModel"
      width="30%"
    >
      <avue-form
        ref="avueForm"
        :option="jcxflOption"
        @reset-change="resetChange"
        @submit="handleSubmit"
        v-model="jcxflForm"
      >
      </avue-form>
      <div class="avue-dialog__footer" slot="footer">
        <el-button size="small" @click="jcxflModel = false">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button size="small" type="primary" @click="$refs.avueForm.submit()">
          保存
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="安全措施项"
      v-if="jcxflModel1"
      v-dialogDrag
      :visible.sync="jcxflModel1"
      width="50%"
    >
      <avue-form
        :option="jcxflOption1"
        @reset-change="resetChange1"
        @submit="handleSubmit1"
        v-model="jcxflForm1"
      >
      </avue-form>
    </el-dialog>
  </el-container>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";
import * as API from "@/api/safetyTtandard/jobTicketSecurityMeasures";
import {exportBlob} from "@/api/common";
import {downloadXls} from "@/util/util";
import GridLayout from "@/views/components/layout/grid-layout";
import {getPage, submit} from "@/api/safetyTtandard/jobTicketSecurityMeasures";

export default {
  name: "index",
  components: {Template, GridLayout, CommonTree},
  computed: {
    corePersonnelTableOption() {
      let column = [
        {
          label: "序号",
          prop: "seq",
          align: "center",
          width: 50,
          overHidden: true,
        },
        {
          label: "安全措施",
          prop: "securityContent",
          align: "left",
          overHidden: true,
        },
      ];
      return {
        index: false,
        menuWidth: 100,
        column: column,
      };
    },
    jcxflOption() {
      return {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            span: 24,
            labelWidth: 200,
            maxlength: 20,
            showWordLimit: true,
            label: "作业活动名称",
            prop: "typeName",
            rules: [
              {
                required: true,
                message: "请输入作业活动名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    jcxflOption1() {
      return {
        span: 24,
        emptyText: "取消",
        emptyIcon: "x",
        submitIcon: "x",
        column: [
          {
            span: 24,
            labelWidth: 100,
            label: "安全措施",
            type: "textarea",
            maxlength: 500,
            showWordLimit: true,
            rows: 3,
            prop: "securityContent",
            rules: [
              {
                required: true,
                message: "请输入安全措施内容",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      defaultProps: {
        children: "children",
        label: "dictValue",
      },
      selectTree: undefined,
      jcxflForm: {},
      jcxflForm1: {},
      jcxflModel: false,
      jcxflModel1: false,
      TableLoading: false,
      TableLoading1: false,
      tableData: [],
      tableData1: [],
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },

      corePersonnelHeadBtnOptions: [
        {label: "新增", emit: "head-add", type: "button", icon: ""},
        {label: "删除", emit: "head-del", type: "button", icon: ""},
      ],
      options: [{label: "新增", emit: "head-add", type: "button", icon: ""}],
    };
  },
  mounted() {
    // this.onload();
  },
  methods: {
    commonTreeData(treeData) {
      this.tableData = treeData;
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    resetChange1() {
      this.jcxflModel1 = false;
      this.jcxflForm1 = {};
    },
    handleSubmit(form, done) {
      API.submit(this.jcxflForm)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.onload();
        })
        .catch((err) => {
          done();
        });
    },
    handleSubmit1(form, done) {
      if (!this.selectTree.dictKey) {
        this.$message.warning("请先选择作业活动");
        return
      }
      let data = {}
      data.id = form.id
      data.securityContent = form.securityContent
      data.jobTicketType = this.selectTree.dictValue
      data.jobTicketTypeCode = this.selectTree.dictKey
      API.submit(data).then((res) => {
        if (res.data.code == 200) {
          this.jcxflModel1 = false;
          this.$message.success(res.data.msg);
          this.onload1();
        }
      })
        .catch((err) => {
          done();
        });
    },
    getTreeAdd(row = {}) {
      this.jcxflForm = {};
      this.jcxflModel = true;
    },
    getTreeDelete(data) {
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return API.stworkchecklistRemove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    getTreeEdit(row) {
      this.jcxflForm = {
        ...row,
        objectName: row.typeName,
        parentName: row.typeName,
      };
      this.jcxflModel = true;
    },
    handleImport() {
      // if (!this.selectTree?.isObj)
      //   return this.$message.success("请选择检查项对象");
      this.excelBox = true;
      this.excelOption.excelForm.excelFile = [];
    },
    headExport() {
      if (!this.selectTree?.isObj)
        return this.$message.success("请选择检查项对象");
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?objectId=${this.selectTree.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    async addjcx(row = {}) {
      if (!this.selectTree) return this.$message.warning("请选择作业票类型！");
      this.jcxflForm1 = row;
      this.jcxflModel1 = true;
      this.$forceUpdate();
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headDel() {
      this.rowDel(this.selectionList, 1);
    },
    rowDel(data, type) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids());
          console.log(formData)
          API.remove(formData)
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          type == 0 ? this.onload() : this.onload1(this.page);
        });
    },
    selectionChange(data) {
      this.selectionList = [];
      this.selectionList = data;
    },
    gridRowClick(row) {
      this.selectTree = row;
      this.onload1(this.page);
    },
    onload() {
      this.tableLoading = true;
      this.TableLoading1 = true;
      API.list().then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.TableLoading1 = false;
          this.tableData = res.data.data;
          this.gridRowClick({row: res.data.data[0]});
        }
      });
    },
    onload1(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      this.TableLoading1 = true;
      API.getPage(this.page.currentPage, this.page.pageSize, {
        ...params,
        jobTicketTypeCode: this.selectTree.dictKey,
      }).then((res) => {
        if (res.data.code == 200) {
          this.TableLoading1 = false;
          this.tableData1 = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.left-box {
  width: 324px;
}

.table-box {
  flex: 1;
}

.gridLayOut {
  margin-top: 5px;
}

::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 223px) !important;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 223px) !important;
  max-height: calc(100vh - 223px) !important;
}
</style>
